<template>
    <v-container class="page-not-found">
        <v-row class="justify-center">
            <v-col cols="auto" class="box">
                <p class="font-weight-black title">Oops!</p>
                <p class="description">
                    We cannot seem to find the page you are looking for...
                </p>
                <p class="text-subtitle-1">Error Code: 404</p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class PageNotFound extends Vue {
    mounted() {
        this.$parent?.$el.classList.add('page-not-found');
    }

    destroyed() {
        this.$parent?.$el.classList.remove('page-not-found');
    }
}
</script>

<style lang="scss">
.v-main.page-not-found,
.v-main.main-background.page-not-found {
    background-color: white !important;
}
</style>

<style lang="scss" scoped>
.page-not-found {
    background-color: white !important;
    background-image: url('@/assets/img/404/full.svg');
    background-position: right center;
    background-size: 50% 50%;
    height: 100%;

    .box {
        height: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .title {
        font-size: 6vh !important;
        line-height: 6vh !important;
    }

    .description {
        font-size: 2vh !important;
        line-height: 2vh !important;
    }
}
</style>
